import React from 'react'
import { GetServerSideProps } from 'next'
import Link from 'next/link'
import { chakra, Box, Flex, Image, VStack, Heading, Text, Icon } from '@chakra-ui/react'
import { getServerSession } from 'next-auth'
import debounce from 'lodash/debounce'
import { GoSearch } from 'react-icons/go'
import { getRoute } from 'utils/helpers'
import { initializeApollo } from 'utils/ApolloProvider'
import { PROFILES } from 'utils/data/profile/operations'
import {
  ProfilesQuery,
  ProfilesQueryVariables,
  ThumbnailSizeEnum,
  useProfilesLazyQuery,
  useProfilesQuery,
} from 'src/generated/graphql-frontend'
import { getNextAuthOptions } from 'src/nextAuthOptions'
import Container from 'components/Container'
import { HeadingPill } from 'components/HeadingPill'
import { Spill1, Spill2 } from 'components/Homepage/Spill'
import { Cards } from 'components/Homepage/Cards'
import { Community } from 'components/Homepage/Community'
import { FAQ, Hand } from 'components/Homepage/FAQ'
import { Footer } from 'components/Footer'
import { InputWrapper } from 'components/InputWrapper'
import { PageWrapper } from 'components/PageWrapper'
import { GetStarted, SignUpChip } from 'components/Homepage'
import useTranslation from 'next-translate/useTranslation'


const platformName = process.env.NEXT_PUBLIC_APP_NAME

const Description = chakra(Text, {
  baseStyle: {
    textAlign: { base: 'center', md: 'left' },
    fontSize: { base: 'sm', md: 'lg' },
    lineHeight: 'tall',
  },
})

const pagination = {
  from: 0,
  length: 10,
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, getNextAuthOptions(context.req))
  if (session?.user.profile) {
    return {
      redirect: {
        destination: getRoute('backoffice'),
        permanent: false,
      },
    }
  }
  const apolloClient = initializeApollo({}, context)
  try {
    await apolloClient.query<ProfilesQuery, ProfilesQueryVariables>({
      query: PROFILES,
      variables: {
        pagination,
        size: ThumbnailSizeEnum.Medium,
        coverPictureSize: ThumbnailSizeEnum.CoverPicture,
      },
    })
    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
      },
    }
  } catch (error) {
    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
      },
    }
  }
}

const Homepage = () => {
  const { t } = useTranslation('homePage')
  const [search, setSearch] = React.useState<string>('')
  const [profiles, { data: debouncedProfilesData, updateQuery }] = useProfilesLazyQuery()
  const debouncedProfiles = React.useRef(debounce(profiles, 1000))
  const { data: profilesData } = useProfilesQuery({
    variables: {
      pagination,
      size: ThumbnailSizeEnum.Medium,
      coverPictureSize: ThumbnailSizeEnum.CoverPicture,
    },
  })

  React.useEffect(() => {
    if (search.trim().length) {
      void debouncedProfiles.current({
        variables: {
          pagination,
          filterData: { search },
          size: ThumbnailSizeEnum.Medium,
          coverPictureSize: ThumbnailSizeEnum.CoverPicture,
        },
      })
    } else if (debouncedProfilesData?.profiles?.profiles.length) {
      updateQuery?.(() => ({
        __typename: 'Query',
        profiles: {
          __typename: 'ProfilesWithPagination',
          pagination: debouncedProfilesData.profiles!.pagination,
          profiles: [],
        },
      }))
    }
  }, [search])

  return (
    <PageWrapper title={t('titleSlogan', { platformName })}>
      <Spill1 />
      <Box overflow="hidden" position="relative" zIndex={1}>
        <Container pt="0" display="flex" flexDirection="column">
          <Flex
            flexDir={{ base: 'column-reverse', md: 'row' }}
            alignItems={{ base: 'center', md: 'center' }}
            justifyContent="space-between"
          >
            <Box
              w={{ base: 'full', sm: 'auto' }}
              flex={{ base: 'none', md: '1' }}
              mt={{ base: '10', md: '-7vw' }}
            >
              <VStack
                alignItems={{ base: 'center', md: 'flex-start' }}
                maxWidth={{ base: 'full', sm: 'xl', md: '45ch' }}
                spacing={{ base: '6', md: '10' }}
              >
                <Heading textAlign={{ base: 'center', md: 'left' }} lineHeight="base">
                  {t('easyWayToAccept')}
                  <br />
                  {t('supportAs')} <HeadingPill bgColor="peach.400">{t('creator')}</HeadingPill>
                </Heading>

                <Description maxW="sm">{t('itsLikeHelpingToGrowAPlant')}</Description>

                <Flex>
                  <GetStarted />
                </Flex>
              </VStack>
            </Box>
            <Box
              flex="1"
              h={{ base: 'auto', md: '45vw' }}
              w={{ base: '90vw', sm: '80vw', md: 'unset' }}
              ml={{ base: 'calc(6vw * -1)', md: '0' }}
              maxH={{ base: 'unset', sm: 'xl', md: '2xl' }}
              minH={{ base: '120vw', sm: '70vw', md: 'xl' }}
              position="relative"
              pointerEvents="none"
            >
              <Cards
                position="absolute"
                left={{ base: '-16px', md: '0' }}
                top={{ base: '16px', md: '0' }}
                right={0}
                bottom={0}
                width="100%"
              />
            </Box>
          </Flex>
        </Container>
        <Box position="relative">
          <Spill2 />
          <Container py={0}>
            <Flex flexDir={{ base: 'column', md: 'row' }} alignItems="center">
              <Box
                flex={1}
                position="relative"
                pt={{ base: '12vw', md: '24' }}
                maxW={{ base: '96vw', md: 'unset' }}
                ml={{ base: '0', md: '-12' }}
                alignSelf="flex-start"
                pointerEvents="none"
                zIndex={0}
              >
                <Image objectFit="cover" src="/images/esoteriq/eye.svg" alt="" />
              </Box>
              <Flex flex={1} mt={{ base: '14', md: '0' }} justifyContent="flex-end" zIndex={1}>
                <Box px={{ base: '0', md: '0' }} maxW={{ base: 'full', md: '55ch' }}>
                  <Heading textAlign={{ base: 'center', md: 'left' }} lineHeight="base">
                    {t('howDoesIt')} <HeadingPill bgColor="teal.400">{t('work')}?</HeadingPill>
                  </Heading>
                  <Description mt="8">
                    {t('youAlwaysHaveSomethingToShow')}
                    <br />
                    <br />
                    {t('planticoIsDonationPlatform', { platformName })}
                  </Description>
                </Box>
              </Flex>
            </Flex>
          </Container>
          <VStack
            mt={{ base: '20', md: '48' }}
            spacing={{ base: '6', md: '10' }}
            position="relative"
            zIndex="1"
            id="creators"
          >
            <Heading lineHeight="base" textAlign="center">
              {t('joinOur')}{' '}
              <HeadingPill translateY="-40%" rotate="-2deg" bgColor="red.300">
                {t('community')}
              </HeadingPill>
            </Heading>
            <Box px={{ base: 5, md: 16 }} maxW={{ base: 'auto', md: '64ch' }}>
              <Description textAlign="center">{t('creatorsGetRewardedForTheirWork', { platformName })}</Description>
            </Box>

            {false && (
              <Box position="relative" width={{ md: '576px' }}>
                <InputWrapper
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  width="100%"
                  rightIcon={
                    <Icon
                      as={GoSearch}
                      width={{ base: '4', md: '6' }}
                      height={{ base: '4', md: '6' }}
                    />
                  }
                />
                {!!debouncedProfilesData?.profiles?.profiles.length && (
                  <Box
                    width="100%"
                    mt="1rem"
                    py="1rem"
                    borderWidth="1px"
                    borderColor="gray.300"
                    borderRadius="lg"
                    position="absolute"
                    bgColor="white"
                    zIndex="overlay"
                  >
                    {debouncedProfilesData?.profiles?.profiles.map(({ id, name, username }) => (
                      <Link href={getRoute('profile', username)} key={id} passHref>
                        <a>
                          <Box p="1rem" _hover={{ cursor: 'pointer', bgColor: 'gray.100' }}>
                            <Text>{name}</Text>
                          </Box>
                        </a>
                      </Link>
                    ))}
                  </Box>
                )}
              </Box>
            )}

            <Container pt="0" display="flex" flexDirection="column" alignItems="center">
              <Community
                maxW={{ base: '100%', md: '4xl' }}
                profiles={profilesData?.profiles?.profiles}
              />
            </Container>
          </VStack>

          <Box position="relative" zIndex={0}>
            <Hand top={{ base: '-3.3rem', md: '-13rem' }} zIndex="0" />
          </Box>
          <Container position="relative" mt={{ base: '4', md: '8' }} mb="12" zIndex={1}>
            <Heading lineHeight="base" textAlign="center">
              <HeadingPill px={{ base: '0.25', md: '2.5' }} bgColor="purple.400">
                {t('faq')}
              </HeadingPill>
            </Heading>
            <FAQ maxW="5xl" mt={{ base: '5', md: '10' }} mx="auto" position="relative" zIndex="1" />
          </Container>
        </Box>
        <Footer mx="auto" maxW="7xl" />
      </Box>
      <SignUpChip />
    </PageWrapper>
  )
}

export default Homepage
