import Image from 'next/legacy/image'
import {
  chakra,
  SlideFade,
  ScaleFade,
  AspectRatio,
  HTMLChakraProps,
  keyframes,
} from '@chakra-ui/react'

const cardRatio = 0.6412556054
const slideStyle = { width: 'inherit', height: 'inherit' }

// this animation makes the idle item move in circle and wiggle a little bit and scale to draw attention
const idleWiggleAnimation = keyframes`
  0% {
    transform: rotateZ(0deg) scale(1);
  }
  50% {
    transform: rotateZ(2deg) scale(1.015);
  }
  100% {
    transform: rotateZ(0deg) scale(1);
  }
`

const getCircularAnimation = (steps = 8, radius = 0.25) => Array.from({ length: steps }, (_, i) => {
    const percent = (i / steps) * 100
    const angle = (i / steps) * 360
    const x = Math.sin(angle) * radius * 100
    const y = Math.cos(angle) * radius * 100
    return `
      ${percent}% {
        transform: rotateZ(calc(var(--chakra-rotate) + ${Math.sin(angle / 2) * 5}deg)) translate3d(${x}%, ${y}%, 0);
      }
    `
  }).join('\n')

const idleCircularAnimation = keyframes`
    ${getCircularAnimation(64)}
  `

interface CardProps extends HTMLChakraProps<'div'> {
  src: string
  delaySeconds: number
  rotateZ: number
  offsetX: number
  offsetY: number
  ratio: number
}

function Card({ src, rotateZ, delaySeconds, offsetX, offsetY, ratio, ...props }: CardProps) {
  return (
    <chakra.div position="absolute" {...props}>
      <SlideFade in delay={delaySeconds} offsetX={offsetX} offsetY={offsetY}>
        <ScaleFade in delay={delaySeconds} initialScale={0.92}>
          <AspectRatio
            width={{
              base: '42vw',
              sm: '31vw',
              md: '265px',
            }}
            ratio={ratio}
            borderRadius="xl"
            overflow="hidden"
            boxShadow="0px 16px 30px rgba(58, 24, 130, 0.409)"
            transform={`rotateZ(${rotateZ}deg)`}
          >
            <Image src={src} objectFit="cover" layout="fill" alt="" />
          </AspectRatio>
        </ScaleFade>
      </SlideFade>
    </chakra.div>
  )
}

export function Cards(props: HTMLChakraProps<'div'>) {
  return (
    <chakra.div {...props}>
      <Card
        left="45%"
        top="3%"
        delaySeconds={0.25}
        ratio={cardRatio}
        rotateZ={10}
        offsetX={0}
        offsetY={50}
        src="/images/esoteriq/3.svg"
        animation={`${idleWiggleAnimation} 4s 1s ease-in-out infinite`}
      />
      <Card
        left="7%"
        top="26%"
        delaySeconds={0.333}
        ratio={cardRatio}
        rotateZ={-15}
        offsetX={50}
        offsetY={60}
        src="/images/esoteriq/4.svg"
        animation={`${idleWiggleAnimation} 3.5s 2s ease-in-out infinite`}
      />
      <Card
        left="60%"
        top="49%"
        delaySeconds={0.57}
        ratio={cardRatio}
        rotateZ={29}
        offsetX={-30}
        offsetY={40}
        src="/images/esoteriq/2.svg"
        animation={`${idleWiggleAnimation} 7s 1s linear infinite`}
      />
      <chakra.div
        position="absolute"
        top={{ base: '88%', md: '92%' }}
        left={{ base: '28%', md: '35%' }}
        rotate="49deg"
        animation={`${idleCircularAnimation} 50s linear infinite`}
      >
        <SlideFade in delay={0.88} offsetY="-40px" style={slideStyle}>
          <Image src="/images/esoteriq/10.svg" width={88} height={88} alt="" />
        </SlideFade>
      </chakra.div>
      <chakra.div
        position="absolute"
        top="8%"
        left="35%"
        width={{ base: '44px', md: '84px' }}
        height={{ base: '44px', md: '84px' }}
        rotate="-28deg"
        animation={`${idleCircularAnimation} 70s linear infinite`}
      >
        <SlideFade in delay={1} offsetY="20px" offsetX="20px" style={slideStyle}>
          <Image src="/images/esoteriq/sun.svg" layout="fill" alt="" />
        </SlideFade>
      </chakra.div>
      {/* <chakra.div
        position="absolute"
        bottom="13%"
        left={{ base: '42%', md: '29%' }}
        width="78px"
        height="78px"
        rotate="-28deg"
        animation={`${idleCircularAnimation} 70s linear infinite`}
      >
        <SlideFade in delay={1.11} offsetY="3px" offsetX="12px" style={slideStyle}>
          <Image src="/images/esoteriq/9.svg" layout="fill" alt="" />
        </SlideFade>
      </chakra.div> */}
      <chakra.div
        position="absolute"
        top={{ base: '30%', md: '38%' }}
        left={{ base: '100%', md: '92%' }}
        width={{ base: '60px', md: '180px' }}
        height={{ base: '60px', md: '180px' }}
        rotate="-17deg"
        animation={`${idleCircularAnimation} 70s linear infinite`}
      >
        <SlideFade in delay={1.24} offsetX="-60px" offsetY="70px" style={slideStyle}>
          <Image src="/images/esoteriq/star.svg" layout="fill" alt="" />
        </SlideFade>
      </chakra.div>
    </chakra.div>
  )
}
