import React from 'react'
import Image from 'next/legacy/image'
import NextLink from 'next/link'
import { chakra, VStack, Link, HTMLChakraProps } from '@chakra-ui/react'
import Logo, { LogoIcon } from 'components/Logo'
import { getRoute } from 'utils/helpers'
import useTranslation from 'next-translate/useTranslation'
import { useDarkSufix } from './Homepage/Spill'
import { getColorModeValue } from 'utils/theme/helpers'


const platformName = process.env.NEXT_PUBLIC_APP_NAME

const CatLink = chakra(Link, {
  baseStyle: {
    color: getColorModeValue('primary.600', 'white'),
    fontWeight: 'bold',
    display: 'block',
  },
})

const NavLink = chakra(Link, {
  baseStyle: {
    color: '#4C5980',
    pr: '3',
    mr: '3',
    flex: 'none',
    borderRight: { base: '1px solid #4c5980', md: 'none' },
    _last: {
      borderRight: 'none',
    },
  },
})

const NavPanel: React.FC<{ children: React.ReactElement[] }> = ({ children }) => (
  <>
    <VStack display={{ base: 'none', md: 'flex' }} spacing="3" alignItems="flex-start">
      {children}
    </VStack>
    <VStack display={{ base: 'flex', md: 'none' }} spacing="4" mb="6" alignItems="flex-start">
      {React.Children.toArray(children)[0]}
      <chakra.div display="flex" flexWrap="wrap" alignItems="flex-start">
        {React.Children.toArray(children).slice(1)}
      </chakra.div>
    </VStack>
  </>
)

export function Footer(props: HTMLChakraProps<'div'>) {
  const { t } = useTranslation('homePage')

  return (
    <chakra.div position="relative" pt={{ base: '18', md: '64' }}>
      <chakra.div
        position="absolute"
        width="100%"
        bottom="0"
        overflow="hidden"
        pointerEvents="none"
      >
        {/* TODO: Fix this mobile wave according to design */}
        <chakra.div
          width="1100px"
          height="700px"
          transform={{ base: 'translateX(-20%)', md: 'none' }}
          display={{ base: 'block', md: 'none' }}
        >
          <Image src={`/images/homepage/footer${useDarkSufix()}.svg`} alt="" objectFit="cover" layout="fill" />
        </chakra.div>
        <chakra.div width="full" display={{ base: 'none', md: 'block' }}>
          <Image
            src={`/images/homepage/footer${useDarkSufix()}.svg`}
            alt=""
            objectFit="fill"
            layout="responsive"
            width="1440"
            height="395"
          />
        </chakra.div>
      </chakra.div>
      <chakra.div
        position="relative"
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
        p={{ base: '4', md: '0' }}
        {...props}
      >
        <Logo
          width="180px"
          height="92px"
          alignSelf="center"
          display={{ base: 'block', md: 'none' }}
        />
        <LogoIcon width="80px" height="84px" mr="12" display={{ base: 'none', md: 'block' }} />
        <chakra.div
          mt={{ base: '5', md: '0' }}
          flex={{ base: 'none', md: '5' }}
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
          flexWrap="wrap"
          fontSize={{ base: 'sm', md: 'lg' }}
          pos="relative"
          zIndex={1}
        >
          <NavPanel>
            <CatLink href="#">{t('footer.aboutUs')}</CatLink>
            <NavLink as={NextLink} href={getRoute('contact')}>{t('footer.aboutPlantico', { platformName })}</NavLink>
            <NavLink as={NextLink} href={getRoute('community')}>{t('footer.community')}</NavLink>
            {/*<NavLink href="#">{t('footer.faq')}</NavLink>*/}
          </NavPanel>
          <NavPanel>
            <CatLink>{t('footer.features')}</CatLink>
            <NavLink href={getRoute('pricing')}>{t('footer.pricing')}</NavLink>
            {/* <NavLink href="#">{t('footer.donationButton')}</NavLink>
            <NavLink href="#">{t('footer.featureRequest')}</NavLink> */}
          </NavPanel>
          <NavPanel>
            <CatLink href="#">{t('footer.helpAndSupport')}</CatLink>
            <NavLink as={NextLink} href={getRoute('termsAndConditions')}>{t('footer.tos')}</NavLink>
            <NavLink as={NextLink} href={getRoute('privacyPolicy')}>{t('footer.privacyPolicy')}</NavLink>
          </NavPanel>
          <NavPanel>
            <CatLink href="#">{t('footer.socials')}</CatLink>
            <NavLink href="https://instagram.com/plantico">Instagram</NavLink>
            <NavLink href="https://facebook.com/plantico.io">Facebook</NavLink>
            <NavLink href="#">Youtube</NavLink>
          </NavPanel>
        </chakra.div>
        <chakra.div
          display={{ base: 'none', md: 'block' }}
          flex="0 0 180px"
          height="14rem"
          position="relative"
          zIndex={0}
        >
          <chakra.div position="absolute" top="-24" marginLeft={-12}>
            <Image
              src="/images/esoteriq/6.svg"
              alt=""
              layout="fixed"
              width="340"
              height="327"
            />
          </chakra.div>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  )
}
